import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../../utils/utilities';
import { NavLink } from 'react-router-dom';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import { currencyFormat } from '../../utils/utilities';

function SavingsAccount() {
    const { userData } = useAuth();
    const [loanDetails, setLoanDetails] = useState([]);

    // Fetch loan details from userData when component mounts or userData changes
    useEffect(() => {
        if (userData?.LoanAccountDetails) {
            setLoanDetails(userData.LoanAccountDetails);
        }
    }, [userData]);

    // Calculate savings balances using useMemo to avoid recalculating on every render
    const savingsBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            const balance = parseFloat(loan["Balance"] || 0);
            switch (loan["Balance Type"]) {
                case "Savings Balance":
                    acc.totalSavingsBalance += balance;
                    break;
                case "Ruwa Stands Balance":
                    acc.totalRuwaStandsBalance += balance;
                    break;
                case "Housing Community Balance":
                    acc.totalHousingCommunityBalance += balance;
                    break;
                case "Student Savings Balance":
                    acc.totalStudentSavingsBalance += balance;
                    break;
                case "Student Shares Balance":
                    acc.totalStudentSharesBalance += balance;
                    break;
                default:
                    break;
            }
            return acc;
        }, {
            totalSavingsBalance: 0,
            totalRuwaStandsBalance: 0,
            totalHousingCommunityBalance: 0,
            totalStudentSavingsBalance: 0,
            totalStudentSharesBalance: 0
        });
    }, [loanDetails]);

    const isValidBalance = (balance) => !isNaN(balance) && balance !== 0;

    const hasSavingsDetails = useMemo(() => {
        return [
            savingsBalances.totalSavingsBalance,
            savingsBalances.totalRuwaStandsBalance,
            savingsBalances.totalHousingCommunityBalance,
            savingsBalances.totalStudentSavingsBalance,
            savingsBalances.totalStudentSharesBalance,
        ].some(isValidBalance);
    }, [savingsBalances]);

    const renderBalanceSection = (label, balance) => {
        return isValidBalance(balance) && (
            <div className='profile-form'>
                <ul>
                    <li>
                        <label>Balance Type:</label>
                        <h4>{label}</h4>
                    </li>
                    <li>
                        <label>Balance:</label>
                        <h4>{currencyFormat(balance)}</h4>
                    </li>
                </ul>
            </div>
        );
    };

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <section className='profile-page'>
            <div className='account-wrapper'>
                <div className='profile-inr menuBar-scroll bg-gradient'>
                    <div className='profile-dtl'>
                        <h3 className='inr-title'>Savings Account Information</h3>
                        {hasSavingsDetails ? (
                            <div className='profile-form-wrapper'>
                                {renderBalanceSection('Savings Balance', savingsBalances.totalSavingsBalance)}
                                {renderBalanceSection('Ruwa Stands Balance', savingsBalances.totalRuwaStandsBalance)}
                                {renderBalanceSection('Housing Community Balance', savingsBalances.totalHousingCommunityBalance)}
                                {renderBalanceSection('Student Savings Balance', savingsBalances.totalStudentSavingsBalance)}
                                {renderBalanceSection('Student Shares Balance', savingsBalances.totalStudentSharesBalance)}
                            </div>
                        ) : (
                            <div className='no-shares'>
                                <p className='custom-alert'>
                                    Please contact us to discuss how you can take advantage of our Savings opportunities
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SavingsAccount;
