import React, { useState, useCallback } from 'react';
import Logo from '../assets/images/ewesacco_logo.svg';
import EyeIcon from '../assets/images/eye-icon.svg';
import Eyeoff from '../assets/images/eye-off.svg';
import MailIcon from '../assets/images/mail-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, validateEmail } from '../utils/utilities';
import { addUserData } from '../database/indexedDB';
import Heart from '../assets/images/heart.svg';

function Login({ InstallButton }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = useCallback(() => {
        setShowPassword(prevState => !prevState);
    }, []);

    const handleEmailChange = useCallback((e) => {
        setUsername(e.target.value);
        if (emailError) setEmailError(''); // Clear email error message on input change
    }, [emailError]);

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
        if (passwordError) setPasswordError(''); // Clear password error message on input change
    }, [passwordError]);

    const validateFields = () => {
        let isValid = true;
        if (username.trim() === '') {
            setEmailError('Please enter your email.');
            isValid = false;
        }
        if (username && !validateEmail(username)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        if (password.trim() === '') {
            setPasswordError('Please enter your password.');
            isValid = false;
        }
        return isValid;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        setEmailError('');
        setPasswordError('');

        if (!validateFields()) {
            setShowLoader(false);
            return;
        }

        const url = `${BASE_URL}login/`;
        const data = { username, password };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'success') {
                    try {
                        await addUserData(result.user_data);
                        navigate('/dashboard');
                        console.log('User data stored successfully in IndexedDB.');
                    } catch (error) {
                        setPasswordError('Error storing user data in IndexedDB.');
                        console.error(error);
                    }
                } else {
                    setPasswordError(result.message || 'Login failed.');
                }
            } else {
                setPasswordError('Network response was not ok.');
            }
        } catch (error) {
            setPasswordError('An error occurred: ' + error.message);
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <section className='auth-page after-layer'>
            <div className='auth-inr'>
                <div className='auth-header'>
                    <img src={Logo} alt='Empowered Woman Excel Sacco Logo' />
                </div>
                <div className='auth-content'>
                    <div className='auth-title'>
                        <p>Welcome to</p>
                        <h2>Empowered Woman <br /> Excel Sacco</h2>
                        {InstallButton}
                    </div>
                    <div className='auth-form-wrap'>
                        <div className='auth-form'>
                            <form onSubmit={handleLogin} noValidate>
                                <div className='form-field'>
                                    <label htmlFor='email'>Email Address</label>
                                    <div className="icon-input-container">
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="Enter your email address"
                                            value={username}
                                            className={`input-field ${emailError ? 'input-error' : ''}`}
                                            autoComplete='username'
                                            onChange={handleEmailChange}
                                            aria-describedby={emailError ? 'email-error' : undefined}
                                            required
                                        />
                                        <span className="input-icon">
                                            <img src={MailIcon} alt='Mail Icon' />
                                        </span>
                                    </div>
                                    {emailError && <p className='error' id='email-error' aria-live="assertive">{emailError}</p>}
                                </div>
                                <div className='form-field'>
                                    <label htmlFor='password'>Password</label>
                                    <div className="icon-input-container">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="Enter your password"
                                            value={password}
                                            className={`input-field ${passwordError ? 'input-error' : ''}`}
                                            autoComplete='new-password'
                                            onChange={handlePasswordChange}
                                            aria-describedby={passwordError ? 'password-error' : undefined}
                                            required
                                        />
                                        <span onClick={togglePasswordVisibility} className="input-icon" role="button" aria-label="Toggle password visibility">
                                            <img src={showPassword ? Eyeoff : EyeIcon} alt='Toggle visibility' />
                                        </span>
                                    </div>
                                    {passwordError && <p className='error' id='password-error' aria-live="assertive">{passwordError}</p>}
                                </div>
                                <div className='form-field link-field'>
                                    <p><Link to='/reset'>Reset password</Link></p>
                                </div>
                                <div className='form-field btn-field'>
                                    <button type="submit" className='custom-btn' disabled={showLoader}>
                                        {showLoader ? (
                                            <div className='spinner-wrap'>
                                                <div className='spinner'></div>
                                            </div>
                                        ) : (
                                            'Login'
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className='text-field'>
                                <p>Don’t have an account? <Link to='/register'>Register Here</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='footer'>
                    <p>
                    Built with <img src={Heart} alt='Heart icon' /> by <strong>Nextige Team, India</strong>
                    </p>
                </div> */}
            </div>
        </section>
    );
}

export default Login;
