import { useAuth } from "../../utils/utilities";
import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ChevronRight from "../../assets/images/chevron-right.svg";

function FormsList() {
  const { userData } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  if (!userData) {
    return <p>Loading...</p>;
  }

  const handleNavigation = (formType) => {
    navigate(`/form/${formType}`);
  };

  return (
    <section className="forms-page">
      <div className="forms-inr">
        <div className="menuBar-scroll bg-gradient">
          <div className="dashboard-wrapper">
            <h3 className="inr-title">Benefits/Loans Applications</h3>
            <div className="forms-listing">
              <ul>
                <li onClick={() => handleNavigation("zero-percent-loan")}>
                  <h4>0% Loan Application Form</h4>
                  <img src={ChevronRight} alt="" />
                </li>
                <li onClick={() => handleNavigation("regular-loan")}>
                <h4>Regular Loan Application Form</h4>
                  <img src={ChevronRight} alt="" />
                </li>
                <li onClick={() => handleNavigation("acknowledgment")}>
                  <h4>Debt Acknowledgement Form</h4>
                  <img src={ChevronRight} alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormsList;
