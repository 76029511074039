import React from 'react';
import { useAuth } from '../../utils/utilities';

function PersonalAccount() {
    const { userData } = useAuth();
    if (!userData) {
        return <p>Loading...</p>;
    }
    // Destructure AccountInformation from userData
    const {
        AccountInformation: {
            AccountNumber = 'N/A',
            accoutholdername = 'N/A',
            Address = 'N/A',
            City = 'N/A',
            PhoneNumber = 'N/A',
            user_email = 'N/A',
        } = {},
    } = userData;

    return (
        <section className='profile-page'>
            <div className='profile-inr menuBar-scroll bg-gradient'>
                <div className='profile-dtl'>
                    <h3 className='inr-title'>Account Holder Information</h3>
                    <div className='profile-form'>
                        <ul>
                            <li>
                                <label>Account Number:</label>
                                <h4>{AccountNumber}</h4>
                            </li>
                            <li>
                                <label>Account Holder:</label>
                                <h4>{accoutholdername}</h4>
                            </li>
                            <li>
                                <label>Address:</label>
                                <h4>{Address}</h4>
                            </li>
                            <li>
                                <label>City:</label>
                                <h4>{City}</h4>
                            </li>
                            <li>
                                <label>Phone Number:</label>
                                <h4>{PhoneNumber}</h4>
                            </li>
                            <li>
                                <label>Email Address:</label>
                                <h4>{user_email}</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PersonalAccount;
