import React from 'react';
import { NavLink } from 'react-router-dom';

function BottomTabMenu({ menuItems }) {
    return (
        <div className='menu-bar'>
            <ul className='menuBar-tabs'>
                {menuItems.map((item, index) => (
                    <li key={index}>
                        <NavLink to={item.path} className={({ isActive }) => (isActive ? 'menu-link active-link' : 'menu-link')}>
                            <img src={item.icon} alt={item.label} />
                            <span>{item.label}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BottomTabMenu;
