import React, { useEffect, useState, useMemo } from 'react';
import './assets/css/global.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import appIcon from '../src/assets/images/logo192.png';
import BottomTabMenu from './components/BottomTabMenu';
import { AnimatePresence, motion } from 'framer-motion';
import { menuItems, accountsMenuItems, noBottomTabRoutes, noHeaderRoutes, backIconRoutes, routesConfig } from './config/navigationRoutes';
import { AuthProvider, updateThemeColor } from './utils/utilities';
import SplashScreen from './components/SplashScreen';
import AppHeader from './components/AppHeader';

function App() {
    const [isSplashVisible, setSplashVisible] = useState(true);
    const [isContentVisible, setContentVisible] = useState(false);
    const [customdeferredPrompt, setCustomDeferredPrompt] = useState(null);
    const location = useLocation();

    const handleSplashLoad = () => {
        setSplashVisible(false);
        setTimeout(() => {
            setContentVisible(true);
        }, 50);
    };

    useEffect(() => {
        document.title = 'EWE Sacco';

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setCustomDeferredPrompt(e);
        });

        window.addEventListener('appinstalled', () => {
            setCustomDeferredPrompt(null);
        });

        if (window.matchMedia('(display-mode: standalone)').matches) {
            setCustomDeferredPrompt(null);
        }
    }, []);

    useEffect(() => {
        const Paths = ['/', '/register', '/forgot'];
        if (Paths.includes(window.location.pathname)) {
            updateThemeColor('#FFFFFF');
        } else {
            updateThemeColor('#1D1E20');
        }
    }, [window.location.pathname]);

    const handleInstallClick = () => {
        if (customdeferredPrompt) {
            customdeferredPrompt.prompt();
            customdeferredPrompt.userChoice.then((choiceResult) => {
                setCustomDeferredPrompt(null);
                console.log(`User ${choiceResult.outcome === 'accepted' ? 'accepted' : 'dismissed'} the A2HS prompt`);
            });
        }
    };

    const InstallButton = useMemo(() => {
        const isMobile = window.innerWidth <= 767;
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
        if (!isMobile) return null;
        if (isIosDevice && !window.navigator.standalone) {
            return (
                <InstallPopup appIcon={appIcon} isIphone={true} message="Install this application on your home screen to easily access it on the go." />
            );
        }

        return (
            location.pathname === '/' && customdeferredPrompt && (
                <InstallPopup appIcon={appIcon} message="Install this application to easily access it on the go." handleClick={handleInstallClick} />
            )
        );
    }, [location.pathname, customdeferredPrompt]);

    const showBottomTab = useMemo(() => !noBottomTabRoutes.includes(location.pathname), [location.pathname]);
    const showHeader = useMemo(() => !noHeaderRoutes.includes(location.pathname), [location.pathname]);
    const isBack = useMemo(() => backIconRoutes.includes(location.pathname), [location.pathname]);

    return (
        <>
            {isSplashVisible ? (
                <SplashScreen onLoad={handleSplashLoad} />
            ) : (
                <div className={`fade-enter ${isContentVisible ? 'fade-enter-active' : ''}`}>
                    {(showHeader || isBack) && (
                        <AppHeader 
                            heading={location.pathname.split('/').pop().replace('-', ' ').replace('/', ' ')} 
                            isBack={isBack} 
                        />
                    )}
                    <AnimatePresence mode="wait">
                        <AuthProvider>
                            <Routes location={location} key={location.pathname}>
                                {routesConfig.map(({ path, component, animation }, index) => (
                                    <Route
                                        key={index}
                                        path={path}
                                        element={
                                            path === '/' ? (
                                                <component.type InstallButton={InstallButton} />
                                            ) : animation ? (
                                                <AnimatedContainer>{component}</AnimatedContainer>
                                            ) : (
                                                component
                                            )
                                        }
                                    />
                                ))}
                            </Routes>
                        </AuthProvider>
                    </AnimatePresence>
                    {showBottomTab && <BottomTabMenu menuItems={isBack ? accountsMenuItems : menuItems} />}
                </div>
            )}
        </>
    );
}

function InstallPopup({ appIcon, handleClick, message, isIphone }) {
    return (
        <div className="install-popup-overlay">
            <div className="install-popup">
                <div className="plus-icon-container">
                    <img src={appIcon} alt="App Icon" />
                </div>
                <br />
                <h3>Install EWE Sacco</h3>
                <p>{message}</p>
                {isIphone && (
                    <p className="share-wrap">
                        Just tap <span className="share-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#007AFF" d="M6 23q-.825 0-1.412-.587T4 21V10q0-.825.588-1.412T6 8h3v2H6v11h12V10h-3V8h3q.825 0 1.413.588T20 10v11q0 .825-.587 1.413T18 23zm5-7V4.825l-1.6 1.6L8 5l4-4l4 4l-1.4 1.425l-1.6-1.6V16z" /></svg>
                        </span> then "<strong>Add to Home Screen</strong>"
                    </p>
                )}
                {handleClick && (
                    <p className="share-wrap">
                        <button onClick={handleClick} className="custom-btn btn-install">
                            Install App
                        </button>
                    </p>
                )}
            </div>
        </div>
    );
}

function AnimatedContainer({ 
    children,
    initial = { opacity: 0, x: 20 },
    animate = { opacity: 1, x: 0 },
    exit = { opacity: 0, x: -20 }, 
    transition = { duration: 0.3 } }) {
    return (
        <motion.div
            initial={initial}
            animate={animate}
            exit={exit}
            transition={transition}
        >
            {children}
        </motion.div>
    );
};

export default App;
