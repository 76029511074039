
import React, { useState, useCallback } from 'react';
import { BASE_URL, getInitials, useAuth } from '../../utils/utilities';
import EyeIcon from '../../assets/images/eye-icon.svg';
import Eyeoff from '../../assets/images/eye-off.svg';
import SuccessIcon from '../../assets/images/success.svg';
import Notification from '../../components/Notification';

function Profile() {
    const { userData } = useAuth();
    const [emailError, setEmailError] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [password, setPassword] = useState('');
    const [cpassword, setcPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    
    const [showLoader, setShowLoader] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const togglePasswordVisibility = useCallback((field) => {
        setPasswordVisibility((prevVisibility) => ({
            ...prevVisibility,
            [field]: !prevVisibility[field],
        }));
    }, []);
    

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
        if (passwordError) setPasswordError(''); // Clear password error message on input change
    }, [passwordError]);

    const handleConfirmPasswordChange = useCallback((e) => {
        setcPassword(e.target.value);
        if (passwordError) setPasswordError(''); // Clear password error message on input change
    }, [passwordError]);

    const handleCurrentPasswordChange = useCallback((e) => {
        setCurrentPassword(e.target.value);
        if (emailError) setEmailError(''); // Clear current password error message on input change
    }, [emailError]);

       // Function to handle form submission
       const handleSubmit = async (e) => {
        e.preventDefault();
        // Client-side validation
        if (!currentPassword) {
            setEmailError('Current password is required');
            return;
        }
        if(password==''){
            setPasswordError('Password is required');
            return;
        }
        if(password && cpassword==''){
            setPasswordError('Confirm password is required');
            return;
        }
        if (password && cpassword && password !== cpassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        if (password && password.length < 5) {
            setPasswordError('New password must be at least 5 characters long');
            return;
        }
       
        setShowLoader(true);
        // Prepare the API request body
        const data = {
            current_password: currentPassword,
            new_password: password,
            confirm_new_password: cpassword,
            user_id: user_id,
        };

        try {
            // Call the custom WordPress API to change the password
            const url = `${BASE_URL}change-password/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (result.status == 'error' && result.message) {
                setPasswordError(result.message);
            } else if(result.status=='success' && result.message) {
                setShowNotification(true);
                setPasswordError('');
                setNotificationMessage(result.message);
            }else{
                setPasswordError('An error occurred while changing the password.');
            }
        } catch (error) {
            setPasswordError('An error occurred while changing the password.');
        } finally {
            setShowLoader(false);
        }
    }

    if (!userData) {
        return <p>Loading...</p>;
    }

    const {
        AccountInformation: {
            AccountNumber = 'N/A',
            accoutholdername = 'N/A',
            Address = 'N/A',
            City = 'N/A',
            PhoneNumber = 'N/A',
            user_email = 'N/A',
            user_id = 0
        } = {},
    } = userData;

    return (
        <>
        {!showNotification && (
            <>
        <section className='profile-page custom-profile-page'>
            <div className='profile-inr bg-gradient'>
                <div className='profile-dtl'>
                    <div className="profile-head">
                        <div className="profile-icon">
                            {getInitials(accoutholdername)}
                        </div>
                        <h3>{accoutholdername}</h3>
                        <span>{user_email}</span>
                    </div>
                    <div className='profile-form'>
                        <ul>
                            <li>
                                <label>Address:</label>
                                <h4>{Address}</h4>
                            </li>
                            <li>
                                <label>City:</label>
                                <h4>{City}</h4>
                            </li>
                            <li>
                                <label>Phone Number:</label>
                                <h4>{PhoneNumber}</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className='profile-page custom-profile-page'>
            <div className='profile-inr bg-gradient'>
                <div className='profile-dtl'>
                    <div className='profile-form'>
                        <h3 className="inr-title">Change Password</h3>
                    <form onSubmit={handleSubmit} noValidate>
                                <div className='form-field'>
                                    <label htmlFor='email'>Current Password</label>
                                    <div className="icon-input-container">
                                        <input
                                            type={passwordVisibility.currentPassword ? 'text' : 'password'}
                                            id="old_password"
                                            placeholder="Enter your current password"
                                            value={currentPassword}
                                            className={`input-field ${emailError ? 'input-error' : ''}`}
                                            autoComplete='old_password'
                                            onChange={handleCurrentPasswordChange}
                                            aria-describedby={emailError ? 'email-error' : undefined}
                                            required
                                        />
                                      <span onClick={() => togglePasswordVisibility('currentPassword')} className="input-icon" role="button" aria-label="Toggle password visibility">
                                            <img src={passwordVisibility.currentPassword ? Eyeoff : EyeIcon} alt='Toggle visibility' />
                                        </span>
                                    </div>
                                    {emailError && <p className='error' id='email-error' aria-live="assertive">{emailError}</p>}
                                </div>
                                <div className='form-field'>
                                    <label htmlFor='password'>New Password</label>
                                    <div className="icon-input-container">
                                        <input
                                            type={passwordVisibility.newPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="Enter your password"
                                            value={password}
                                            className={`input-field`}
                                            autoComplete='new-password'
                                            onChange={handlePasswordChange}
                                            aria-describedby={passwordError ? 'password-error' : undefined}
                                            required
                                        />
                                        <span onClick={() => togglePasswordVisibility('newPassword')} className="input-icon" role="button" aria-label="Toggle password visibility">
                                            <img src={passwordVisibility.newPassword ? Eyeoff : EyeIcon} alt='Toggle visibility' />
                                        </span>
                                    </div>
                                </div>
                                <div className='form-field'>
                                    <label htmlFor='password'>Confirm New Password</label>
                                    <div className="icon-input-container">
                                        <input
                                            type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="Enter your password"
                                            value={cpassword}
                                            className={`input-field`}
                                            autoComplete='new-password'
                                            onChange={handleConfirmPasswordChange}
                                            aria-describedby={passwordError ? 'password-error' : undefined}
                                            required
                                        />
                                          <span onClick={() => togglePasswordVisibility('confirmPassword')} className="input-icon" role="button" aria-label="Toggle password visibility">
                                            <img src={passwordVisibility.confirmPassword ? Eyeoff : EyeIcon} alt='Toggle visibility' />
                                        </span>
                                    </div>
                                    {passwordError && <p className='error' id='password-error' aria-live="assertive">{passwordError}</p>}
                                </div>
                                <div className='form-field btn-field'>
                                    <button type="submit" className='custom-btn' disabled={showLoader}>
                                        {showLoader ? (
                                            <div className='spinner-wrap'>
                                                <div className='spinner'></div>
                                            </div>
                                        ) : (
                                            'Update'
                                        )}
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </section>
        </>
            )}
        {showNotification && (
                <Notification
                    icon={SuccessIcon}
                    title="Success"
                    message={notificationMessage}
                    route="/settings"
                    buttonText="Back to Settings"
                />
            )}

        </>
    );
}

export default Profile;