import { openDB } from 'idb';

const DB_NAME = 'ewesacco-db';
const DB_VERSION = 1;
const STORE_NAME = 'userStore';

let dbInstance = null; // Cache the database instance

// Initialize IndexedDB
export const initDB = async () => {
    if (!dbInstance) {
        dbInstance = await openDB(DB_NAME, DB_VERSION, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
                }
            },
        });
    }
    return dbInstance;
};

// Store data in IndexedDB
export const addUserData = async (data) => {
    try {
        const db = await initDB();
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);
        await store.add(data);
        await tx.done;
    } catch (error) {
        console.error('Failed to add user data:', error);
    }
};

// Get data from IndexedDB
export const getUserData = async () => {
    try {
        const db = await initDB();
        const tx = db.transaction(STORE_NAME, 'readonly');
        const store = tx.objectStore(STORE_NAME);
        const allData = await store.getAll();
        await tx.done;
        return allData.length ? allData[0] : null;
    } catch (error) {
        console.error('Failed to get user data:', error);
        return null;
    }
};

// Update data in IndexedDB
export const updateUserData = async (id, newData) => {
    try {
        const db = await initDB();
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);
        await store.put({ ...newData, id });
        await tx.done;
    } catch (error) {
        console.error('Failed to update user data:', error);
    }
};

// Delete all user data from IndexedDB
export const deleteUserData = async () => {
    try {
        const db = await initDB();
        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);
        await store.clear(); // Clear all records
        await tx.done;
    } catch (error) {
        console.error('Failed to delete user data:', error);
    }
};

// Check for specific form data type
export const checkFormData = async (type) => {
    try {
        const userData = await getUserData();
        if (!userData || !userData.formdata) {
            return false;
        }
        return userData.formdata.includes(type);
    } catch (error) {
        console.error('Failed to check form data:', error);
        return false;
    }
};
