import React from 'react';
import LogoutIcon from '../assets/images/log-out.svg';
import ArrowLeft from '../assets/images/arrow-left.svg';
import { Link, useNavigate } from 'react-router-dom';
import { deleteUserData } from '../database/indexedDB';
import DashboardIcon from '../assets/images/home.svg';

function AppHeader({ heading, isBack }) {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await deleteUserData();
            navigate('/');
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    };
    const handleBackClick = () => {
        navigate(-1); // Go back one step in the browser history
    };
    const goDashboard = () => {
        navigate('/dashboard'); // Navigate to the dashboard
    };
    

    return (
        <div className='page-header'>
            <h2 className='page-title'>
                {isBack ? (
                    <>
                        <Link onClick={handleBackClick} className="back-link">
                            <img src={ArrowLeft} alt='Go back to previous page' />
                            <span>{heading}</span>
                        </Link>
                        <button onClick={goDashboard} className='transparent-btn icon-btn' aria-label="Dashboard">
                            <img src={DashboardIcon} alt='Dashboard Icon' />
                        </button>
                    </>
                ) : (
                    heading
                )}
            </h2>
            {!isBack && (
                <button onClick={handleLogout} className='transparent-btn icon-btn' aria-label="Logout">
                    <img src={LogoutIcon} alt='Logout Icon' />
                </button>
            )}
        </div>
    );
};

export default AppHeader;
