import React, { useState, useCallback } from 'react';
import Logo from '../assets/images/ewesacco_logo.svg';
import MailIcon from '../assets/images/mail-icon.svg';
import { Link } from 'react-router-dom';
import { validateEmail, BASE_URL } from '../utils/utilities';
import Notification from '../components/Notification';
import SuccessIcon from '../assets/images/success.svg';
import Heart from '../assets/images/heart.svg';

function ForgotPassword() {
    const [formState, setFormState] = useState({
        username: '',
        emailError: '',
        showLoader: false,
        showNotification: false,
        notificationMessage: '',
    });

    const handleEmailChange = useCallback((e) => {
        setFormState((prevState) => ({
            ...prevState,
            username: e.target.value,
            emailError: '',
        }));
    }, []);

    const toggleLoader = (status) => {
        setFormState((prevState) => ({
            ...prevState,
            showLoader: status,
        }));
    };

    const sendResetPasswordLink = async (email) => {
        try {
            const response = await fetch(`${BASE_URL}forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok && data.status === 'success') {
                setFormState((prevState) => ({
                    ...prevState,
                    showNotification: true,
                    notificationMessage: "We've sent a password reset link to your email. Please check your inbox to reset your password.",
                }));
            } else {
                setFormState((prevState) => ({
                    ...prevState,
                    emailError: data.message || 'Failed to send reset link.',
                }));
            }
        } catch (error) {
            setFormState((prevState) => ({
                ...prevState,
                emailError: 'Failed to send reset link. Please try again.',
            }));
            console.error('Error sending reset link:', error);
        }
    };

    const handleReset = useCallback(
        async (e) => {
            e.preventDefault();
            const { username } = formState;

            if (!username) {
                setFormState((prevState) => ({
                    ...prevState,
                    emailError: 'Please enter an email address.',
                }));
                return;
            }

            if (!validateEmail(username)) {
                setFormState((prevState) => ({
                    ...prevState,
                    emailError: 'Please enter a valid email address.',
                }));
                return;
            }

            toggleLoader(true);

            try {
                await sendResetPasswordLink(username);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                toggleLoader(false);
            }
        },
        [formState.username]
    );

    const { username, emailError, showLoader, showNotification, notificationMessage } = formState;

    return (
        <section className='auth-page after-layer'>
            {!showNotification && ( <div className='auth-inr'>
                <div className='auth-header'>
                    <img src={Logo} alt='Company Logo' />
                </div>
                <div className='auth-content'>
                    <div className='auth-title'>
                        <p>Enter your details to</p>
                        <h2>Reset Password</h2>
                    </div>
                    <div className='auth-form-wrap'>
                        <div className='auth-form'>
                            <form onSubmit={handleReset} noValidate>
                                <div className='form-field'>
                                    <label htmlFor='email'>Email Address</label>
                                    <div className="icon-input-container">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email address"
                                            value={username}
                                            className={`input-field ${emailError ? 'input-error' : ''}`}
                                            onChange={handleEmailChange}
                                            aria-describedby={emailError ? 'email-error' : undefined}
                                            required
                                        />
                                        <span className="input-icon">
                                            <img src={MailIcon} alt='Mail Icon' />
                                        </span>
                                    </div>
                                    {emailError && <p className='error' id='email-error'>{emailError}</p>}
                                </div>
                                <div className='form-field btn-field'>
                                    <button type="submit" className='custom-btn' disabled={showLoader}>
                                        {showLoader ? (
                                            <div className='spinner-wrap'>
                                                <div className='spinner'></div>
                                            </div>
                                        ) : (
                                            'Send'
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className='text-field'>
                                <p>Already have an account? <Link to='/'>Login Here</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='footer'>
                    <p>
                    Built with <img src={Heart} alt='Heart icon' /> by <strong>Nextige Team, India</strong>
                    </p>
                </div> */}
            </div>)}

            {showNotification && (
                <Notification
                    icon={SuccessIcon}
                    title="Success"
                    message={notificationMessage}
                    route="/"
                    buttonText="Back to login"
                />
            )}
        </section>
    );
}

export default ForgotPassword;
