import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../../utils/utilities';
import UserLock from '../../assets/images/user-lock-svgrepo.svg';
import Handshake from '../../assets/images/handshake-svgrepo.svg';
import MoneyFinance from '../../assets/images/money-finance-business.svg';
import ShareIcon from '../../assets/images/share-android-svgrepo.svg';
import { Link } from 'react-router-dom';
import { currencyFormat, formatDateTime } from '../../utils/utilities';

function Dashboard() {
    const { userData } = useAuth();
    const [loanDetails, setLoanDetails] = useState([]);

    useEffect(() => {
        if (userData?.LoanAccountDetails) {
            setLoanDetails(userData.LoanAccountDetails);
        }
    }, [userData]);

    const hours = new Date().getHours();
    const timeOfDay = hours < 12 ? 'Morning' : hours < 17 ? 'Afternoon' : 'Evening';

    // Memoize calculations to prevent unnecessary recalculations
    const principalBalance = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            if (loan["Total Principal Balance"] !== 'NULL') {
                return acc + parseFloat(loan["Total Principal Balance"] || 0);
            }
            return acc;
        }, 0);
    }, [loanDetails]);

    const savingsBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            const balance = parseFloat(loan["Balance"] || 0);
            switch (loan["Balance Type"]) {
                case "Savings Balance":
                    acc.totalSavingsBalance += balance;
                    break;
                case "Ruwa Stands Balance":
                    acc.totalRuwaStandsBalance += balance;
                    break;
                case "Housing Community Balance":
                    acc.totalHousingCommunityBalance += balance;
                    break;
                case "Student Savings Balance":
                    acc.totalStudentSavingsBalance += balance;
                    break;
                case "Student Shares Balance":
                    acc.Totalstudentsharesbalance += balance;
                    break;
                default:
                    break;
            }
            return acc;
        }, {
            totalSavingsBalance: 0,
            totalRuwaStandsBalance: 0,
            totalHousingCommunityBalance: 0,
            totalStudentSavingsBalance: 0,
            Totalstudentsharesbalance: 0
        });
    }, [loanDetails]);

    const sharesBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            if (loan["Total Shares Balance"] !== 'NULL') {
                return acc + parseFloat(loan["Total Shares Balance"] || 0);
            }
            return acc;
        }, 0);
    }, [loanDetails]);

    const formatDateTimestr = useMemo(() => {
        if (loanDetails.length > 0) {
            // const createdDate = loanDetails[0]['Created Date'];
            const createdDateTime = loanDetails[0]['Created DateTime'];
            return formatDateTime(`${createdDateTime}`);
        }
        return '';
    }, [loanDetails]);

    const hasBalance = (balance) => balance !== null && !isNaN(balance) && balance !== 0;

    // Helper function to render balance items
    const renderBalanceItem = (label, balance, className = '') => (
        hasBalance(balance) && (
            className ? (
                <div className={`list-box ${className}`}>
                    <div className="list-detail">
                        <p>{label}</p>
                        <h3>{currencyFormat(balance)}</h3>
                    </div>
                </div>
            ) : (
                <div className="list-detail">
                    <p>{label}</p>
                    <h3>{currencyFormat(balance)}</h3>
                </div>
            )
        )
    );
    

    return (
        <section className="dashboard-page">
            <div className='dashboard-inr menuBar-scroll bg-gradient'>
                <div className='dashboard-wrapper'>
                    <h3 className='greeting-text'>Hi {userData?.AccountInformation?.accoutholdername}, Good {timeOfDay}!</h3>
                    <div className='dashboard-listing'>
                        {renderBalanceItem('Total Principal Balance', principalBalance, 'box-principal-balance')}
                        
                        {(hasBalance(savingsBalances.totalSavingsBalance) || 
                          hasBalance(savingsBalances.totalRuwaStandsBalance) || 
                          hasBalance(savingsBalances.totalHousingCommunityBalance) || 
                          hasBalance(savingsBalances.totalStudentSavingsBalance) || 
                          hasBalance(savingsBalances.Totalstudentsharesbalance)) && (
                            <div className='list-box box-total-savings-balance'>
                                {renderBalanceItem('Savings Balance', savingsBalances.totalSavingsBalance)}
                                {renderBalanceItem('Ruwa Stands Balance', savingsBalances.totalRuwaStandsBalance)}
                                {renderBalanceItem('Housing Community Balance', savingsBalances.totalHousingCommunityBalance)}
                                {renderBalanceItem('Student Savings Balance', savingsBalances.totalStudentSavingsBalance)}
                                {renderBalanceItem('Student Shares Balance', savingsBalances.Totalstudentsharesbalance)}
                            </div>
                        )}

                        {renderBalanceItem('Total Shares Balance', sharesBalances, 'box-total-student-shares-balance')}
                        
                        {formatDateTimestr && (
                            <div className='notes-wrapper'>
                                <span>The figures displayed in this application are as of: <strong>{formatDateTimestr}</strong></span>
                            </div>
                        )}
                    </div>

                    <div className='box-wrapper'>
                        <DashboardLink to="/account-details" icon={UserLock} label="Personal Details" />
                        <DashboardLink to="/loans" icon={Handshake} label="Loan Account" />
                        <DashboardLink to="/savings" icon={MoneyFinance} label="Savings Account" />
                        <DashboardLink to="/shares" icon={ShareIcon} label="Shares Account" />
                    </div>
                </div>
            </div>
        </section>
    );
}

// Reusable component for dashboard links
const DashboardLink = ({ to, icon, label }) => (
    <div className='dashboard-box'>
        <Link to={to}>
            <div className='dash-box-inr'>
                <img src={icon} alt={`${label} icon`} />
                <p>{label}</p>
            </div>
        </Link>
    </div>
);

export default Dashboard;
