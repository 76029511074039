import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Ensure this import is correct
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
    <App />
  </Router>,
  // </React.StrictMode>
);

// Register the service worker for PWA
serviceWorkerRegistration.register(); // Use register() to enable PWA features