import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../../utils/utilities';
import { currencyFormat } from '../../utils/utilities';
import IconCheck from '../../assets/images/icon_checkbox.svg';

function SharesAccount() {
    const { userData } = useAuth();
    const [loanDetails, setLoanDetails] = useState([]);

    // useEffect to set loan details when userData changes
    useEffect(() => {
        if (userData?.LoanAccountDetails) {
            setLoanDetails(userData.LoanAccountDetails);
        }
    }, [userData]);

    // Calculate Total Shares Balance using useMemo for performance optimization
    const sharesBalances = useMemo(() => {
        return loanDetails.reduce((acc, loan) => {
            const balance = parseFloat(loan["Total Shares Balance"] || 0);
            return loan["Total Shares Balance"] !== 'NULL' ? acc + balance : acc;
        }, 0);
    }, [loanDetails]);

    // Check if balance is valid
    const isValidBalance = (balance) => !isNaN(balance) && balance !== 0;

    // Early return if userData is not available
    if (!userData) {
        return <p>Loading...</p>;
    }

    // Helper function to render shares info
    const renderSharesInfo = () => (
        <div className='profile-form'>
            <ul>
                <li>
                    <label>Balance Type:</label>
                    <h4>Shares Balance</h4>
                </li>
                <li>
                    <label>Balance:</label>
                    <h4>{currencyFormat(sharesBalances)}</h4>
                </li>
            </ul>
        </div>
    );

    // Helper function to render no shares info
    const renderNoSharesInfo = () => (
        <div className='no-shares'>
            <p>Open a Shares Account with us today to begin your Investment Journey. Here are the options available to you:</p>
            <div className='profile-form'>
                <ul className='no-shares-list'>
                    {renderShareOption('One Share: (min)', 10.00)}
                    {renderShareOption('Executive Five Shares:', 50.00)}
                    {renderShareOption('Monthly Saving (min):', 10.00)}
                    {renderShareOption('Platinum Ten Shares:', 100.00)}
                    <li>
                        <label>
                            <img src={IconCheck} alt='Checked icon' />
                            <span>Monthly Admin Fee: <strong>{currencyFormat(1.00)}</strong></span>
                        </label>
                        <h4 className='anual-fee'>{currencyFormat(12)} per annum</h4>
                    </li>
                </ul>
            </div>
            <div className='profile-form'>
                <div className="women-wealth">
                    <h3>Once off shares purchase women of wealth</h3>
                </div>
                <ul className='no-shares-list'>
                    {renderShareOption('200 Shares:', 2000)}
                    {renderShareOption('500 Shares:', 5000)}
                </ul>
            </div>
        </div>
    );

    const renderShareOption = (label, amount) => (
        <li key={label}>
            <label>
                <img src={IconCheck} alt='Checked icon' />
                <span>{label} <strong>{currencyFormat(amount)}</strong></span>
            </label>
        </li>
    );

    return (
        <section className='profile-page'>
            <div className='account-wrapper'>
                <div className='profile-inr shares-page menuBar-scroll bg-gradient'>
                    <div className='profile-dtl'>
                        <h3 className='inr-title'>Shares Account Information</h3>
                        {isValidBalance(sharesBalances) ? renderSharesInfo() : renderNoSharesInfo()}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SharesAccount;
