import React, { useEffect } from 'react';
import Logo from '../assets/images/ewesacco_logo.svg';
import Heart from '../assets/images/heart.svg';

const SplashScreen = React.memo(({ onLoad }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        onLoad();
      } catch (error) {
        console.error('Error while loading:', error);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [onLoad]);

  return (
    <section className="splash-screen">
      <div>
        <img src={Logo} alt='EweSacco Logo' />
      </div>
      {/* <div className='footer'>
        <p>
          Built with <img src={Heart} alt='Heart icon' /> by <strong>Nextige Team, India</strong>
        </p>
      </div> */}
    </section>
  );
});

export default SplashScreen;
