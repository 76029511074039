import React, { useEffect, useState } from 'react';
import { useAuth } from '../../utils/utilities';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import ZeroPercentLoanForm from './ZeroPercentLoanForm';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import AcknowledgmentForm from './AcknowledgmentForm'; 
import RegularLoanForm from './RegularLoanForm';
import { useLocation } from 'react-router-dom';

function FormDetail() {
    const { formType } = useParams();
    const { userData } = useAuth();
    const [alreadySubmit, setalreadySubmit] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);

    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation();

    const handleBackClick = () => {
        navigate(-1); // Go back one step in the browser history
    };

    useEffect(() => {
        var zero_percent_loan = localStorage.getItem('zero_percent_loan');
        var regular_loan = localStorage.getItem('regular_loan');
        var acknowledgment = localStorage.getItem('acknowledgment');
        if (userData) {
            let submitted = false;
            if (formType === 'zero-percent-loan' && (userData.formdata.zero_percent_loan || zero_percent_loan)) {
                submitted = true;
            } else if (formType === 'regular-loan' && (userData.formdata.regular_loan || regular_loan)) {
                submitted = true;
            } else if (formType === 'acknowledgment' && (userData.formdata.acknowledgment || acknowledgment)) {
                submitted = true;
            }
            setalreadySubmit(submitted);
        }
    }, [formType, userData]);

    useEffect(() => {
        const handleFocus = () => {
          // Keyboard is assumed to be opened
          setKeyboardOpen(true);
        };
    
        const handleBlur = () => {
          // Keyboard is assumed to be closed
          setKeyboardOpen(false);
        };
    
        window.addEventListener('focusin', handleFocus); // for input focus (keyboard open)
        window.addEventListener('focusout', handleBlur); // for input blur (keyboard close)
    
        return () => {
          window.removeEventListener('focusin', handleFocus);
          window.removeEventListener('focusout', handleBlur);
        };
      }, []);
      useEffect(()=>{
        console.log(location.state);
    },[location]);
    if (!userData) {
        return <p>Loading...</p>;
    }

  
    return (
        <>
            <section className='forms-page'>
                <div className='forms-inr'>
                    <div className='page-header'>
                        <h2 className='page-title'>
                            <Link onClick={handleBackClick}>
                                <img src={ArrowLeft} alt='' />
                                <span>{formType.replaceAll('zero-percent','0%').replaceAll('-', ' ')}</span>
                            </Link>
                        </h2>
                    </div>
                    <div className='forms-wrapper menuBar-scroll bg-gradient'>
                        <div className={`dashboard-wrapper`}>
                            {/* <h3 className='inr-title'>{formType.replaceAll('-', ' ')}</h3> */}
                            <div className={`single-form-wrapper ${keyboardOpen ? 'keyboard-open' : ''}`}>
                                <>
                                    {formType === 'zero-percent-loan' ? (
                                        <ZeroPercentLoanForm />
                                    ) : formType === 'regular-loan' ? (
                                        <RegularLoanForm />
                                    ) : formType === 'acknowledgment' ? (
                                        <AcknowledgmentForm />
                                    ) : (
                                        <p>No form available for the selected type.</p>
                                    )}
                                </>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default FormDetail;
