import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Logo from '../assets/images/ewesacco_logo.svg';
import DummyImage from '../assets/images/dummy-image.svg';
import { BASE_URL } from '../utils/utilities';

function Registration() {
    const [showPassword, setShowPassword] = useState(false);
    const [fileName, setFileName] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [formFields, setFormFields] = useState({});
    const [errors, setErrors] = useState({});
    const [successMessage, setsuccessMessage] = useState('');
    const [errorMessage, seterrorMessage] = useState('');
    const [heading, setHeading] = useState('');

    const handleChange = (e) => {
        console.log(e.target.type);
        const { name, value, type } = e.target;
        // Update form data
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // Clear errors for the updated field if the value is present
        if (type !== 'email') {
            if (value.trim() !== '') {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    if (updatedErrors[name]) {
                        delete updatedErrors[name];
                    }
                    return updatedErrors;
                });
            }
        } else {
            // Check if the email field is not empty
            if (value.trim() === '') {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    if (updatedErrors[name]) {
                        delete updatedErrors[name];
                    }
                    return updatedErrors;
                });
            } else {
                // Check if the email is in a valid format
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailPattern.test(value.trim())) {
                    setErrors((prevErrors) => {
                        const updatedErrors = { ...prevErrors };
                        if (updatedErrors[name]) {
                            delete updatedErrors[name];
                        }
                        return updatedErrors;
                    });
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: 'Invalid email address.',
                    }));
                }
            }
        }
        
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const name = file ? file.name : "No file chosen";
        setFileName(name); // Update file name state
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: file
        }));
    };

    const handleDateChange = (date, label) => {
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = localDate.toISOString().split("T")[0]; // yyyy-mm-dd
        const fieldname = label;
    
        // Update form data with the new date
        setFormData({
            ...formData,
            [fieldname]: formattedDate
        });
    
        // Remove the error for this date field, if any
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors[fieldname]) {
                delete updatedErrors[fieldname];
            }
            return updatedErrors;
        });
    };
    

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const getMemberShipData = async () => {
        const url = BASE_URL + 'membership/';
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'success') {
                    setFormFields(result.results);
                } else {
                    console.error('API responded with an error:', result);
                }
            } else {
                console.error('Network response was not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const parseSerializedPHPArray = (serializedString) => {
        const regex = /s:(\d+):"([^"]*)"/g;
        const result = [];
        let match;

        while (match = regex.exec(serializedString)) {
            result.push(match[2]);
        }

        return result;
    };

    useEffect(() => {
        getMemberShipData();
    }, []);

    useEffect(() => {
        if (formFields[1]) {
            // Find the first RichText field
            const firstRichTextField = formFields[1].find(field => field.field_type === 'RichText');
            if (firstRichTextField) {
                setHeading(removeStyleAttribute(firstRichTextField.field_value));
            }
        }
    }, [formFields, 1]);

    const removeStyleAttribute = (html) => {
        if (html) {

            return html.replace(/style="[^"]*"/g, '');
        }
        return html;
    };

    const renderField = (field, index) => {
        const formattedLabel = field.field_label.toLowerCase().replace(/\s+/g, '_') + '_' + field.field_id;
        const field_id = index;
        const field_type = field.field_type;
        const field_value = removeStyleAttribute(field.field_value);

        return (
            <div className='form-field' key={field_id}>
                {(() => {
                    switch (field_type) {
                        case 'Textbox':
                        case 'Lname':
                        case 'Fname':
                        case 'Address':
                        case 'Custom':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>

                                    <input
                                        className='input-field'
                                        type="text"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Number':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="number"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Email':
                        case 'SecEmail':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="email"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'UserPassword':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="password"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Phone':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="number"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'RichText':
                            return field.field_value === "" ? (
                                <h6>{field.field_label}</h6>
                            ) : (
                                index === 0 ?
                                    <></>
                                    :
                                    <div className={index} dangerouslySetInnerHTML={{ __html: field_value }} />
                            );
                        case 'Select':
                            const options = field_value.split(',');
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="custom-select-wrapper">
                                        <select
                                            className='input-field'
                                            name={formattedLabel}
                                            value={formData[formattedLabel] || ''}
                                            onChange={handleChange}
                                            required={field.field_is_required === 1}
                                        >
                                            <option value="">Select an option</option>
                                            {options.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <span className='custom-arrow'></span>
                                    </div>
                                </>
                            );
                        case 'Radio':
                            const radioOptions = parseSerializedPHPArray(field_value);
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="radio-field-wrapper">
                                        {radioOptions.map((option, idx) => (
                                            <div key={idx} className="radio-field">
                                                <input
                                                    className='radio'
                                                    type="radio"
                                                    id={`${formattedLabel}-${idx}`}
                                                    name={formattedLabel}
                                                    value={option}
                                                    checked={formData[formattedLabel] === option}
                                                    onChange={handleChange}
                                                    required={field.field_is_required === 1}
                                                />
                                                <label className="radio-label" htmlFor={`${formattedLabel}-${idx}`}>{option}</label>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            );
                        case 'jQueryUIDate':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <DatePicker
                                        className='input-field'
                                        type="date"
                                        name={formattedLabel}
                                        placeholderText="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        selected={formData[formattedLabel]}
                                        onChange={(date) => handleDateChange(date, formattedLabel)}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'File':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required == 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="drag-file-area">
                                        <img src={DummyImage} alt="image icon" className="image-preview" />
                                        <h4 className="dynamic-message">{fileName || "Attach a Copy of Your Identification Card"}</h4>
                                        <label className="label">
                                            <span className="browse-files">
                                                <input
                                                    type="file"
                                                    className="default-file-input"
                                                    name={formattedLabel}
                                                    onChange={handleFileChange}
                                                />
                                                <span className="browse-files-text">Choose File</span>
                                            </span>
                                        </label>
                                    </div>
                                </>
                            );
                        default:
                            return null;
                    }
                })()}
                {errors[formattedLabel] && <span className='error'>{errors[formattedLabel]}</span>}
            </div>
        );
    };
    const validateStep = (fields) => {
        const newErrors = {};
    
        fields.forEach(field => {
            const fieldName = field.field_label.toLowerCase().replace(/\s+/g, '_') + '_' + field.field_id;
    
            if (field.field_options.field_is_required == 1) {
                if (formData[fieldName] === undefined || formData[fieldName] === '') {
                    newErrors[fieldName] = `${field.field_label} is required.`;
                } 
            }
            if (formData[fieldName] && (field.field_type === 'Email' || field.field_type === 'SecEmail')) {
                // Email validation
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(formData[fieldName])) {
                    newErrors[fieldName] = `Invalid email format.`;
                }
            }
        });
    
        setErrors(newErrors);
    
        return Object.keys(newErrors).length === 0;
    };
    

    const handleNext = () => {
        const fields = formFields[currentStep] || [];
        if (validateStep(fields)) {
            setCurrentStep(prevStep => prevStep + 1);
        } else {
        }
    };

    const handlePrev = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = formFields[currentStep] || [];
        if (validateStep(fields)) {
            // Submit the form
            // Clear previous messages
            seterrorMessage('');
            setsuccessMessage('');

            // Prepare the data
            const url = BASE_URL + 'submit-membership/';
            const formDataObject = new FormData();

            // Append form fields to FormData
            for (const [key, value] of Object.entries(formData)) {
                // Handle file inputs
                if (value instanceof File) {
                    formDataObject.append(key, value);
                } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                    value.forEach(file => formDataObject.append(key, file));
                } else {
                    // For other types of data
                    formDataObject.append(key, value);
                }
            }

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    body: formDataObject,
                });
                if (response.ok) {
                    const result = await response.json();
                    if (result.status === 'success') {
                        setsuccessMessage('Thank you for submitting your form.');
                        setFormData({}); // Reset the formData state
                    } else {
                        seterrorMessage(result.message);
                    }
                } else {
                    seterrorMessage('Response is not OK');
                }
            } catch (error) {
                // seterrorMessage('An error occurred');
            }
        } else {
        }
    };

    return (
        <>
            <section className='auth-page register-page after-layer'>
                <div className='auth-inr'>
                    {Object.keys(formFields).length === 0 ? (
                        <div className='spinner-wrap'>
                            <div className='spinner'>
                            </div>
                        </div>
                    ) : (
                        <div className='bg-gradient'>
                            <div className='auth-header'>
                                <img src={Logo} alt='Logo' />
                            </div>
                            <div className='auth-content'>
                                <div className='auth-title'>
                                    {/* <p>Welcome to</p> */}
                                    <div className='heading-wrap' dangerouslySetInnerHTML={{ __html: heading }} />
                                </div>
                                <div className='auth-form-wrap'>
                                    <div className='auth-form'>
                                        <div className='register-wrap'>
                                            {errorMessage && <p className="error">{errorMessage}</p>}
                                            {successMessage && <p className="success">{successMessage}</p>}
                                            <form onSubmit={handleSubmit} encType='multipart/formdata'>
                                                {Object.keys(formFields).length > 1 && (
                                                    <div className='step-indicator'>
                                                        {Object.entries(formFields).map(([sectionId, fields]) => (
                                                            sectionId >= 1 && (
                                                                <div
                                                                    key={sectionId}
                                                                    className={`step-number ${currentStep == sectionId ? 'active' : ''}`}
                                                                >
                                                                    {sectionId}
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>
                                                )}
                                                {Object.entries(formFields).map(([sectionId, fields]) => (
                                                    <div key={sectionId} className={Number(sectionId) === currentStep ? 'form-active' : 'form-inactive'}>
                                                        {/* <h2>Page {sectionId}</h2> */}
                                                        {fields.map((field, index) => renderField(field, index))}
                                                        {Number(sectionId) === currentStep && (
                                                            <div className={`${currentStep == 1 ? 'pagination-wrapper align-right' : 'pagination-wrapper'}`}>
                                                                {Number(sectionId) > 1 && <button className='custom-btn prev-btn' type="button" onClick={handlePrev}>Prev</button>}
                                                                {Number(sectionId) < Object.keys(formFields).length && <button className='custom-btn' type="button" onClick={handleNext}>Next</button>}
                                                                {Number(sectionId) === Object.keys(formFields).length && <button className='btn-submit custom-btn' type="submit">Submit</button>}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default Registration;
