import React from 'react';
import { Link } from 'react-router-dom';

const Notification = ({ icon, title, message, route, buttonText = 'Back to dashboard' }) => {
    return (
        <section className="success-screen">
            <div>
                <img src={icon} alt={`${title} icon`} />
                <h2>{title}</h2>
                <p>{message}</p>
            </div>
            <div className='btn-field'>
                <Link to={route} className='custom-btn'>{buttonText}</Link>
            </div>
            {/* Add any animations or images here */}
        </section>
    );
};

Notification.defaultProps = {
    icon: '',
    title: 'Notification',
    message: '',
    route: '/dashboard',
    buttonText: 'Back to dashboard',
};

export default Notification;
