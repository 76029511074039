import React, { useEffect, useState } from 'react';
import { useAuth } from '../../utils/utilities';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import ArrowLeft from '../../assets/images/arrow-left.svg';
import Profile from './Profile';
import ContactUs from './ContactUs';
import Disclaimer from './Disclaimer';

function SettingDetail() {
    const { page } = useParams();
    const { userData } = useAuth();
    const navigate = useNavigate(); // Initialize useNavigate
    const handleBackClick = () => {
        navigate(-1); // Go back one step in the browser history
    };

    return (
        <>
            <section className='forms-page'>
                <div className='forms-inr'>
                    <div className='forms-wrapper menuBar-scroll bg-gradient'>
                        <>
                            {page === 'profile' ? (
                                <Profile />
                            ) : page === 'contact-us' ? (
                                <ContactUs />
                            ) : page === 'disclaimer' ? (
                                <Disclaimer />
                            ) : (
                                <p>No page available for the selected type.</p>
                            )}
                        </>
                    </div>
                </div>
            </section>
        </>
    );
}
export default SettingDetail;
