import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useNavigate
import Notification from '../../components/Notification';
import { currencyFormat,BASE_URL,useAuth } from '../../utils/utilities';
import Success from '../../assets/images/success.svg';
import DatePicker from 'react-datepicker';
import DummyImage from '../../assets/images/dummy-image.svg';
import SuccessIcon from '../../assets/images/success.svg';

function ZeroPercentLoanForm() {
    const { userData } = useAuth();
    const { formType } = useParams();
    const [successMessage, setsuccessMessage] = useState('');
    const [errorMessage, seterrorMessage] = useState('');
    const [alreadySubmit, setalreadySubmit] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [fileName, setFileName] = useState('No file chosen');
    const [monthlyPayment, setMonthlyPayment] = useState(null);

    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState({});

    const handleDateChange = (date, label) => {
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = localDate.toISOString().split("T")[0]; // yyyy-mm-dd
        const fieldname = label;
    
        // Update form data with the new date
        setFormData({
            ...formData,
            [fieldname]: formattedDate
        });
    
        // Remove the error for this date field, if any
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors[fieldname]) {
                delete updatedErrors[fieldname];
            }
            return updatedErrors;
        });
    };
    const handleFileChange = (e) => {
        const fileName = e.target.files[0]?.name || "No file chosen";
        setFileName(fileName);
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.files[0]
        }));
    };
    const parseSerializedPHPArray = (serializedString) => {
        const regex = /s:(\d+):"([^"]*)"/g;
        const result = [];
        let match;

        while (match = regex.exec(serializedString)) {
            result.push(match[2]);
        }

        return result;
    };



    const handleChange = (e) => {
        console.log(e.target.type);
        const { name, value, type } = e.target;
        // Update form data
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // Clear errors for the updated field if the value is present
        if (type !== 'email') {
            if (value.trim() !== '') {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    if (updatedErrors[name]) {
                        delete updatedErrors[name];
                    }
                    return updatedErrors;
                });
            }
        } else {
            // Check if the email field is not empty
            if (value.trim() === '') {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    if (updatedErrors[name]) {
                        delete updatedErrors[name];
                    }
                    return updatedErrors;
                });
            } else {
                // Check if the email is in a valid format
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailPattern.test(value.trim())) {
                    setErrors((prevErrors) => {
                        const updatedErrors = { ...prevErrors };
                        if (updatedErrors[name]) {
                            delete updatedErrors[name];
                        }
                        return updatedErrors;
                    });
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: 'Invalid email address.',
                    }));
                }
            }
        }
        
        
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.loanAmount || parseFloat(formData.loanAmount) <= 0) {
            newErrors.loanAmount = 'Please enter a valid loan amount.';
        }

        if (!formData.repayment) {
            newErrors.repayment = 'Please select a repayment option.';
        }

        if (!formData.membershipStatus) {
            newErrors.membershipStatus = 'Please select your SACCO membership status.';
        }
        if (!formData.signature) {
            newErrors.signature = 'Please enter your signature.';
        }
        if (!formData.date) {
            newErrors.date = 'Please select a date.';
        }
        return newErrors;
    };


    const getLoanForm = async () => {
        const url = BASE_URL + 'membership/?form_id=8';
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'success') {
                    setFormFields(result.results);
                } else {
                    console.error('API responded with an error:', result);
                }
            } else {
                console.error('Network response was not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Clear previous messages
    //     seterrorMessage('');
    //     setsuccessMessage('');

    //     // Validate form
    //     const validationErrors = validateForm();
    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return;
    //     }

    //     // Prepare the data
    //     const url = BASE_URL + 'submit-data/';
    //     const data = formData;

    //     try {
    //         const response = await fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Origin': 'http://localhost:3000',
    //                 'Access-Control-Request-Method': 'POST'
    //             },
    //             body: JSON.stringify(data),
    //         });

    //         if (response.ok) {
    //             const result = await response.json();
    //             if (result) {
    //                 setsuccessMessage('Thank you for submitting your 0% Loan Form.');
    //                 // Retrieve existing data from local storage
    //                 const existingUserData = localStorage.getItem('user_data');
    //                 let userDataStorage = existingUserData ? JSON.parse(existingUserData) : {};

    //                 // Initialize formdata if it doesn't exist
    //                 if (!userDataStorage.formdata) {
    //                     userDataStorage.formdata = {};
    //                 }
    //                 // Update form data in local storage
    //                 userDataStorage.formdata[formData.formtype.replaceAll('-', '_')] = formData;
    //                 localStorage.setItem(formData.formtype.replaceAll('-', '_'), JSON.stringify(formData));
    //                 localStorage.setItem('user_data', JSON.stringify(userDataStorage));
    //                 setalreadySubmit(true);
    //             } else {
    //                 seterrorMessage('Something went wrong');
    //             }
    //         } else {
    //             seterrorMessage('Response is not OK');
    //         }
    //     } catch (error) {
    //         seterrorMessage('An error occurred');
    //     }
    // };
    useEffect(() => {
        getLoanForm();
        // randomNotification();
    }, []);

    const removeStyleAttribute = (html) => {
        if (html) {

            return html.replace(/style="[^"]*"/g, '');
        }
        return html;
    };

    const calculateMonthlyPayment = () => {
        if (formData['loan_amount_191'] && formData['repayments_192']) {
            const payment = parseInt(formData['loan_amount_191'].replace(/^\$/, '')) / parseInt(formData['repayments_192'].replace(/^\$/, ''));
            const roundedPayment = Math.ceil(payment); // Rounding to the nearest integer
            const formatedroundedPayment = currencyFormat(roundedPayment, false);
            setMonthlyPayment(formatedroundedPayment);
        } else {
            setMonthlyPayment(currencyFormat('NULL', false));
        }
    };
    useEffect(() => {
        calculateMonthlyPayment();
    }, [formData['repayments_192'], formData['loan_amount_191']]);

    const renderField = (field, index) => {
        const formattedLabel = field.field_label.toLowerCase().replace(/\s+/g, '_') + '_' + field.field_id;
        const field_id = index;
        const field_type = field.field_type;
        const field_value = removeStyleAttribute(field.field_value);

        return (
            <div className='form-field' key={field_id}>
                {(() => {
                    switch (field_type) {
                        case 'Textbox':
                        case 'Lname':
                        case 'Fname':
                        case 'Address':
                        case 'Custom':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="text"
                                        name={formattedLabel}
                                        value={formattedLabel === 'loan_amount_191' && formData[formattedLabel] ?
                                            ('$' + formData[formattedLabel].replace(/^\$/, '')) :
                                            formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Number':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="number"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Email':
                        case 'SecEmail':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="email"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'UserPassword':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="password"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'Phone':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <input
                                        className='input-field'
                                        type="tel"
                                        name={formattedLabel}
                                        value={formData[formattedLabel] || ''}
                                        onChange={handleChange}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'RichText':
                            return field.field_value == "" ? (
                                <h6>{field.field_label}</h6>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: field_value }} />
                            );
                        case 'Select':
                            const options = field_value.split(',');
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="custom-select-wrapper">
                                        <select
                                            className='input-field'
                                            name={formattedLabel}
                                            value={formData[formattedLabel] || ''}
                                            onChange={handleChange}
                                            required={field.field_is_required === 1}
                                        >
                                            <option value="">Select an option</option>
                                            {options.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <span className='custom-arrow'></span>
                                    </div>
                                </>
                            );
                        case 'Radio':
                            const radioOptions = parseSerializedPHPArray(field_value);
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="radio-field-wrapper">
                                        {radioOptions.map((option, idx) => (
                                            <div key={idx} className="radio-field">
                                                <input
                                                    className='radio'
                                                    type="radio"
                                                    id={`${formattedLabel}-${idx}`}
                                                    name={formattedLabel}
                                                    value={option}
                                                    checked={formData[formattedLabel] === option}
                                                    onChange={handleChange}
                                                    required={field.field_is_required === 1}
                                                />
                                                <label className="radio-label" htmlFor={`${formattedLabel}-${idx}`}>{option}</label>
                                            </div>
                                        ))}
                                    </div>

                                    {formattedLabel == 'repayments_192' ? <>
                                        <br />
                                        <div className='form-field'>
                                            <label>
                                                Monthly Installments
                                            </label>
                                            <input
                                                className='input-field'
                                                type="text"
                                                name={'calculated'}
                                                placeholder='Monthly Installments'
                                                value={monthlyPayment}
                                                readOnly={true}
                                            />
                                        </div>
                                    </> : ''}
                                </>
                            );
                        case 'jQueryUIDate':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <DatePicker
                                        className='input-field'
                                        type="date"
                                        name={formattedLabel}
                                        placeholderText="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        selected={formData[formattedLabel]}
                                        onChange={(date) => handleDateChange(date, formattedLabel)}
                                        required={field.field_is_required === 1}
                                    />
                                </>
                            );
                        case 'File':
                            return (
                                <>
                                    <label>
                                        {field.field_label}
                                        {field.field_options.field_is_required === 1 && <span className='text-red'>*</span>}
                                    </label>
                                    <div className="drag-file-area">
                                        <img src={DummyImage} alt="image icon" className="image-preview" />
                                        <label className="label">
                                            <span className="browse-files">
                                                <input
                                                    type="file"
                                                    className="default-file-input"
                                                    name={formattedLabel}
                                                    onChange={handleFileChange}
                                                />  <span className="browse-files-text">Choose File</span>
                                            </span>
                                        </label>
                                    </div>
                                </>
                            );
                        default:
                            return null;
                    }
                })()}
                {errors[formattedLabel] && <span className='error'>{errors[formattedLabel]}</span>}
            </div>
        );
    };

    const validateStep = (fields) => {
        const newErrors = {};
    
        fields.forEach(field => {
            const fieldName = field.field_label.toLowerCase().replace(/\s+/g, '_') + '_' + field.field_id;
    
            if (field.field_options.field_is_required == 1) {
                if (formData[fieldName] === undefined || formData[fieldName] === '') {
                    newErrors[fieldName] = `${field.field_label} is required.`;
                } 
            }
            if (formData[fieldName] && (field.field_type === 'Email' || field.field_type === 'SecEmail')) {
                // Email validation
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(formData[fieldName])) {
                    newErrors[fieldName] = `Invalid email format.`;
                }
            }
        });
    
        setErrors(newErrors);
    
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        const fields = formFields[currentStep] || [];
        if (validateStep(fields)) {
            setCurrentStep(prevStep => prevStep + 1);
        } else {
        }
    };

    const handlePrev = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = formFields[currentStep] || [];
        if (validateStep(fields)) {
            // Submit the form
            formData.user_id = userData.AccountInformation.user_id;
            formData.form_id = 8;
            // Clear previous messages
            seterrorMessage('');
            setsuccessMessage('');

            // Prepare the data
            const url = BASE_URL + 'submit-loan-form/';
            const formDataObject = new FormData();

            // Append form fields to FormData
            for (const [key, value] of Object.entries(formData)) {
                // Handle file inputs
                if (value instanceof File) {
                    formDataObject.append(key, value);
                } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                    value.forEach(file => formDataObject.append(key, file));
                } else {
                    // For other types of data
                    formDataObject.append(key, value);
                }
            }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    body: formDataObject,
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.status === 'success') {
                        setsuccessMessage('Thank you for submitting your zero percent loan form.');
                        // Retrieve existing data from local storage
                        const existingUserData = localStorage.getItem('user_data');
                        let userDataStorage = existingUserData ? JSON.parse(existingUserData) : {};

                        // Initialize formdata if it doesn't exist
                        if (!userDataStorage.formdata) {
                            userDataStorage.formdata = {};
                        }
                        // Update form data in local storage
                        // userDataStorage.formdata[formData.formtype.replaceAll('-', '_')] = formData;
                        // localStorage.setItem(formData.formtype.replaceAll('-', '_'), JSON.stringify(formData));
                        // localStorage.setItem('user_data', JSON.stringify(userDataStorage));
                        setalreadySubmit(true);
                        setFormData({}); // Reset the formData state

                    } else {
                        seterrorMessage(result.message);
                    }
                } else {
                    seterrorMessage('Response is not OK');
                }
            } catch (error) {
                // seterrorMessage('An error occurred');
            }
        } else {
        }
    };
    useEffect(() => {
        var zero_percent_loan = localStorage.getItem('zero_percent_loan');
        var regular_loan = localStorage.getItem('regular_loan');
        var acknowledgment = localStorage.getItem('acknowledgment');
        if (userData) {
            let submitted = false;
            if (formType === 'zero-percent-loan' && (userData.formdata.zero_percent_loan || zero_percent_loan)) {
                submitted = true;
            } else if (formType === 'regular-loan' && (userData.formdata.regular_loan || regular_loan)) {
                submitted = true;
            } else if (formType === 'acknowledgment' && (userData.formdata.acknowledgment || acknowledgment)) {
                submitted = true;
            }
            setalreadySubmit(submitted);
        }
    }, [formType, userData]);


    if (!userData) {
        return <p>Loading...</p>;
    }
    return (
        <div className='form-warp'>
            {successMessage == '' && alreadySubmit ? <h2 className='custom-alert-1'>   <img src={Success} alt='' /> You have already submitted.</h2> :
                <>
                    {successMessage && alreadySubmit ? 
                      <Notification
                      icon={SuccessIcon}
                      title="Success"
                      message={successMessage}
                      route="/dashboard"
                      buttonText="Go to dashboard"
                  />
                    :
                        <>
                            {Object.keys(formFields).length === 0 ? (
                                <div className='spinner-wrap'>
                                    <div className='spinner'>
                                    </div>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit} encType='multipart/formdata'>
                                    {Object.keys(formFields).length > 1 && (
                                        <div className='step-indicator'>
                                            {Object.entries(formFields).map(([sectionId, fields]) => (
                                                sectionId >= 1 && (
                                                    <div
                                                        key={sectionId}
                                                        className={`step-number ${currentStep == sectionId ? 'active' : ''}`}
                                                    >
                                                        {sectionId}
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    )}
                                    {Object.entries(formFields).map(([sectionId, fields]) => (
                                        <div key={sectionId} className={Number(sectionId) === currentStep ? 'form-active' : 'form-inactive'}>
                                            {/* <h2>Page {sectionId}</h2> */}
                                            {fields.map((field, index) => renderField(field, index))}
                                            {Number(sectionId) === currentStep && (
                                                <div className={`${currentStep == 1 ? 'pagination-wrapper align-right' : 'pagination-wrapper'}`}>
                                                    {Number(sectionId) > 1 && <button className='custom-btn prev-btn' type="button" onClick={handlePrev}>Prev</button>}
                                                    {Number(sectionId) < Object.keys(formFields).length && <button className='custom-btn' type="button" onClick={handleNext}>Next</button>}
                                                    {Number(sectionId) === Object.keys(formFields).length && <button className='btn-submit custom-btn' type="submit">Submit</button>}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </form>
                            )}
                        </>
                    }
                </>
            }
        </div>
    );
}

export default ZeroPercentLoanForm;
