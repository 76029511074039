
import React from 'react';
function Disclaimer() {
    return (
        <section className='profile-page disclaimer-page'>
            <div className='profile-inr full-dvh bg-gradient'>
                <div className='profile-dtl'>
                    <h3 className='inr-title'>Empowered Woman Excel Disclaimer</h3>
                    <p>Please read this disclaimer carefully before using this Mobile Application operated by Empowered Woman Excel Savings and Cooperative (SACCO).</p>

                    <p>The content displayed on the Mobile Application is the intellectual property of Empowered Woman Excel SACCO. You may not reuse, republish, or reprint such content without our written consent.</p>

                    <p>All information posted is merely for informational and registration purposes for our registered members as well as those that intend to become our members or would so like to enquire. It is not intended as a substitute for professional advice, nor to be shared with any other parties that do not have the right of entry onto the site. Should you decide to act upon any information on this Mobile Application without proper membership, you do so at your own risk.</p>

                    <p>While the information on this Mobile Application has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.</p>

                    <p>We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this platform, or contact our offices located at: 282 Herbert Chitepo Avenue, Harare, Zimbabwe.</p>
                </div>
            </div>
        </section>
    );
}

export default Disclaimer;