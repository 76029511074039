import DashboardIcon from '../assets/images/dashboard.svg';
import UserLock from '../assets/images/user-lock-svgrepo.svg';
import Handshake from '../assets/images/handshake-svgrepo.svg';
import MoneyFinance from '../assets/images/money-finance-business.svg';
import ShareIcon from '../assets/images/share-android-svgrepo.svg';
import SettingsIcon from '../assets/images/settings.svg';
import FormCheckIcon from '../assets/images/form.svg';
import AccountUserIcon from '../assets/images/square-user-svgrepo.svg';

 // routes
import Login from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import Registration from '../auth/Registration';
import Dashboard from '../pages/dashboard/Dashboard';
import PersonalAccount from '../pages/accounts/PersonalAccount';
import LoanAccount from '../pages/accounts/LoanAccount';
import SavingsAccount from '../pages/accounts/SavingsAccount';
import SharesAccount from '../pages/accounts/SharesAccount';
import FormsList from '../pages/forms/FormsList';
import FormDetail from '../pages/forms/FormDetail';
import Settings from '../pages/settings/Settings';
import SettingDetail from '../pages/settings/SettingDetail';

// Define paths that do not show the bottom tab menu
export const noBottomTabRoutes = [
    '/',
    '/register',
    '/reset',
    '/form/zero-percent-loan',
    '/form/regular-loan',
    '/form/acknowledgment',
    '/setting/profile',
    '/setting/contact-us',
    '/setting/disclaimer',
];

// Define paths that do not show the header
export const noHeaderRoutes = [
    '/',
    '/register',
    '/reset',
    '/account-details',
    '/loans',
    '/savings',
    '/shares',
    '/form/zero-percent-loan',
    '/form/regular-loan',
    '/form/acknowledgment',
];

// Define paths where a back icon is shown
export const backIconRoutes = [
    '/account-details',
    '/loans',
    '/savings',
    '/shares',
    '/setting/profile',
    '/setting/contact-us',
    '/setting/disclaimer',
];


// Main navigation menu items
export const menuItems = [
    { path: '/dashboard', icon: DashboardIcon, label: 'Dashboard' },
    { path: '/account-details', icon: AccountUserIcon, label: 'Accounts' },
    { path: '/forms', icon: FormCheckIcon, label: 'Forms' },
    { path: '/settings', icon: SettingsIcon, label: 'Settings' },
];

// Account-specific navigation menu items
export const accountsMenuItems = [
    { path: '/account-details', icon: UserLock, label: 'Account' },
    { path: '/loans', icon: Handshake, label: 'Loans' },
    { path: '/savings', icon: MoneyFinance, label: 'Savings' },
    { path: '/shares', icon: ShareIcon, label: 'Shares' },
];

export const routesConfig = [
    { path: "/", component: <Login />, animation: true },
    { path: "/forgot-password", component: <ForgotPassword />, animation: true },
    { path: "/dashboard", component: <Dashboard />, animation: true },
    { path: "/account-details", component: <PersonalAccount />, animation: true },
    { path: "/loans", component: <LoanAccount />, animation: true },
    { path: "/shares", component: <SharesAccount />, animation: true },
    { path: "/savings", component: <SavingsAccount />, animation: true },
    { path: "/register", component: <Registration />, animation: false },
    { path: "/reset", component: <ForgotPassword />, animation: true },
    { path: "/forms", component: <FormsList />, animation: true },
    { path: "/form/:formType", component: <FormDetail />, animation: false },
    { path: "/settings", component: <Settings />, animation: true },
    { path: "/setting/:page", component: <SettingDetail />, animation: true }
];

