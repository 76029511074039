
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronRight from "../../assets/images/chevron-right.svg";
import { deleteUserData } from '../../database/indexedDB';

function Settings() {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigation = (formType) => {
    navigate(`/setting/${formType}`);
  };

  const handleLogout = async () => {
    try {
        await deleteUserData();
        navigate('/');
    } catch (error) {
        console.error('Failed to log out:', error);
    }
};

    return (
        <section className='profile-page main-setting-page'>
            <div className='profile-inr menuBar-scroll bg-gradient'>
                <div className='profile-dtl'>
                    <h3 className='inr-title'>User Settings</h3>
                    <div className='profile-form'>
                        <ul className='profile-list-inner'>
                        <li onClick={() => handleNavigation("profile")}>
                        <h4>Profile</h4>
                          <img src={ChevronRight} alt="" />
                         </li>

                         <li onClick={() => handleNavigation("disclaimer")}>
                        <h4>Disclaimer</h4>
                          <img src={ChevronRight} alt="" />
                         </li>

                         <li onClick={() => handleNavigation("contact-us")}>
                        <h4>Contact Us</h4>
                          <img src={ChevronRight} alt="" />
                         </li>

                         <li onClick={() => handleLogout()}>
                        <h4>Logout</h4>
                          <img src={ChevronRight} alt="" />
                         </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Settings;