
import React from 'react';
function ContactUs() {
    return (
        <section className='profile-page'>
            <div className='profile-inr full-dvh bg-gradient'>
                <div className='profile-dtl'>
                    <h3 className='inr-title'>Contact Us Info</h3>
                    <div className='profile-form'>
                        <ul>
                            <li>
                                <label>Address</label>
                                <h4>282 Hebert Chitepo Avenues Harare</h4>
                            </li>
                            <li>
                                <label>Phone Number:</label>
                                <h4>+263 7 79060002</h4>
                            </li>
                            <li>
                                <label>Email:</label>
                                <h4>info@ewesacco.org</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;