import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../../utils/utilities';
import { currencyFormat, formatOnlyDate } from '../../utils/utilities';

// Reusable Accordion Component
const Accordion = ({ title, children, defaultOpen = false }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggleAccordion = () => setIsOpen(!isOpen);

    return (
        <div className="card accordion-item profile-form">
            <h4 className='inr-title' onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                <span>{title}</span>
                {isOpen ? (
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8L8 1L1 8" stroke="#1D1E20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : (
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L8 8L15 1" stroke="#1D1E20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}
            </h4>
            {isOpen && <div className="card-section">{children}</div>}
        </div>
    );
};

// Component to display loan details
const LoanDetailList = ({ details }) => (
    <ul>
        {Object.entries(details).map(([label, value]) => (
            value ? (
                <li key={label}>
                    <label>{label}:</label> <h4>{value}</h4>
                </li>
            ) : null
        ))}
    </ul>
);

function LoanAccount() {
    const { userData } = useAuth();
    const [loanDetails, setLoanDetails] = useState([]);
    const [selectedLoan, setSelectedLoan] = useState(null);

    // Set initial loan data
    useEffect(() => {
        if (userData?.LoanAccountDetails) {
            const validLoans = userData.LoanAccountDetails.filter(loan => loan["Loan Number"] !== "NULL");
            setLoanDetails(validLoans);
            if (validLoans.length > 0) setSelectedLoan(validLoans[0]);
        }
    }, [userData]);

    const interestBalances = useMemo(() => loanDetails.filter(loan => loan["Balance Type"] === "Interest Balance"), [loanDetails]);
    const penaltyBalances = useMemo(() => loanDetails.filter(loan => loan["Balance Type"] === "Penalty Balance"), [loanDetails]);

    const getBalance = (balanceArray, index) => (balanceArray[index]?.Balance || "N/A");

    // const calculateDaysToNextPayment = (dueDate) => {
    //     const nextInstalmentDate = new Date(dueDate);
    //     if (isNaN(nextInstalmentDate)) return dueDate;
    //     const today = new Date();
    //     const daysDiff = Math.ceil((nextInstalmentDate - today) / (1000 * 60 * 60 * 24));
    //     return daysDiff >= 0 ? `${daysDiff} Days` : "Due Date Passed";
    // };

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <section className='profile-page'>
            <div className='account-wrapper'>
                <div className='profile-inr menuBar-scroll bg-gradient'>
                    <div className='profile-dtl'>
                        <h3 className="inr-title">
                            <span>Loan Account Details 
                                {/* <label className="badge">{loanDetails.length}</label> */}
                            </span>
                        </h3>
                        {loanDetails.length === 0 ? (
                            <p>No loan details available.</p>
                        ) : (
                            <>
                                <div className='select-account'>
                                    <div className='form-field'>
                                        <label>Select loan account to see the details</label>
                                        <ul>
                                            {loanDetails.map((loan, index) => (
                                                <li key={index} className='list-account'>
                                                    <div className='radio-field'>
                                                        <input
                                                            className="radio"
                                                            type='radio'
                                                            name='unique_row_id'
                                                            id={`loan-${loan['Unique Row ID']}`}
                                                            value={loan['Unique Row ID']}
                                                            checked={selectedLoan && selectedLoan['Unique Row ID'] === loan['Unique Row ID']}
                                                            onChange={() => setSelectedLoan(loan)}
                                                        />
                                                        <label className='radio-label' htmlFor={`loan-${loan['Unique Row ID']}`}>
                                                            <span>Loan No. {loan["Loan Number"]}</span>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {selectedLoan && (
                                    <>
                                        <Accordion title="Loan Status" defaultOpen>
                                            <LoanDetailList details={{
                                                "Loan Number": selectedLoan["Loan Number"],
                                                "Scheme Account": userData.AccountInformation?.SchemeAccountNumber,
                                                "Loan Status": <span className={`${selectedLoan["Loan Status"]} status-label`}>{selectedLoan["Loan Status"]}</span>,
                                                "Loan Date": formatOnlyDate(selectedLoan["Loan Date"]),
                                                "Loan Amount": currencyFormat(selectedLoan["Loan Amount"]),
                                                "Principal Balance": currencyFormat(selectedLoan["Balance"]),
                                                "Accrued Interest": currencyFormat(selectedLoan["Loan Accrued Interest"]),
                                                "Interest Balance": currencyFormat(getBalance(interestBalances, loanDetails.indexOf(selectedLoan))),
                                                "Penalty Balance": currencyFormat(getBalance(penaltyBalances, loanDetails.indexOf(selectedLoan))),
                                            }} />
                                        </Accordion>
                                        <Accordion title="Payment Details">
                                            <LoanDetailList details={{
                                                "Next Payment Due Date": formatOnlyDate(selectedLoan["Next Instalment Due Date"]),
                                                "Total Installment Amount Due": currencyFormat(selectedLoan['Instalment Amount Due']),
                                                "Is Within 10 Days To Next Payment": <span className={selectedLoan["Generate 10 Days To Due Date Notice"] == 'N' || selectedLoan["Generate 10 Days To Due Date Notice"].toLowerCase() == 'no' ? 'Paid status-label' : 'Due status-label'}>{selectedLoan["Generate 10 Days To Due Date Notice"]}</span>,
                                                "Days To Next Payment": selectedLoan["Days To Next Instalment Due Date"],
                                                "Previous Payment Amount":currencyFormat(selectedLoan['Last Payment Amount']),
                                                "Previous Payment Date": formatOnlyDate(selectedLoan["Last Payment Date"]),
                                                "Days Since Last Payment": selectedLoan["Days Since Last Payment"],
                                                "Amount Paid To Date": selectedLoan["Total payments To Date"],
                                                "Payoff Amount": selectedLoan["Payoff Amount"],
                                            }} />
                                        </Accordion>
                                        <Accordion title="Installments Details">
                                            <LoanDetailList details={{
                                                "Installment Amount": currencyFormat(selectedLoan['Loan Instalment Amount']),
                                                "Total Number of Installments": selectedLoan['Number of Instalments'],
                                                "Installments Start Date": formatOnlyDate(selectedLoan['Instalments Start Date']),
                                                "Installments Last Date": formatOnlyDate(selectedLoan['Instalments Last Date']),
                                                "Current Installment Period": selectedLoan['Current Instalment Period'],
                                                "Installments Period Due": selectedLoan['Instalments Period Due'],
                                                "Next Installment Date": formatOnlyDate(selectedLoan['Next Instalment Date']),
                                                "Remaining Instalments": selectedLoan['Remaining Instalments'],
                                            }} />
                                        </Accordion>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoanAccount;
